import React, { Component } from 'react';
import Products from '../components/products';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import Request from '../core/apiService';

const req = new Request();

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: []
		}
	}

	componentDidMount() {
		this.loadProducts();
	}

	async loadProducts() {
		this.setState({loading: true});
		const res = await req.get('productos/home');
		if (res.productos) {
			this.setState({products: res.productos})
		}
		this.setState({loading: false});
	}

	render() {
		let products_array = [];
		let pages = 1;
		if (Array.isArray(this.state.products)) {
			pages = Math.ceil(this.state.products.length / 6);
			for (let i = 1; i <= pages; i++) {
				products_array.push(this.state.products.slice((i - 1) * 6, i * 6));
				products_array = products_array.slice(0, 4);
			}
		}
		return (
			<div className="column align-center justify-center">
				<Carousel
					infinite
					centered
					dots
					keepDirectionWhenDragging
					autoPlay={4000}
				>
					<img src="/images/slider1.jpg" alt="slider1"/>
					<img src="/images/slider1.jpg" alt="slider2"/>
					<img src="/images/slider1.jpg" alt="slider3"/>
				</Carousel>
				<div className="white-space-16"/>
				<Carousel
					infinite
					centered
					dots
					keepDirectionWhenDragging
					clickToChange
				>
					{products_array.map(products =>
						<Products
							products	=	{products}
							loading		= 	{this.state.loading}/>
					)}
				</Carousel>
			</div>
		)
	}
}

export default Home;
