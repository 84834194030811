import React, { Component } from 'react';
import Gifts from '../components/gifts';
import Request from '../core/apiService';
import { Link } from 'react-router-dom';

const req = new Request();

class GiftOptions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gifts: [],
			selectedGifts: [],
			currentSale: 0
		}
	}

	componentDidMount() {
		this.loadProducts();
	}

	async loadProducts() {
		this.setState({loading: true});
		const res = await req.get('productos/gifts');
		if (res.productos) {
			this.setState({gifts: res.productos})
		}
		let id_producto = localStorage.getItem('aroos_current_sale');
		if (id_producto) {
			id_producto = parseInt(id_producto);
			let localData = localStorage.getItem('aroos_sale');
			if (localData) {
				localData = JSON.parse(localData);
				if (Array.isArray(localData)) {
					const product = localData.find(ld => ld.id_producto == id_producto)
					if (product) {
						this.setState({selectedGifts: product.gifts || []});
					}
				}
			}
			this.setState({currentSale: id_producto});
		} else {
			this.props.history.push("/")
		}
		this.setState({loading: false});
	}

	selectGift(gift) {
		const giftInfo = {
			id_producto: gift.id_producto,
			nombre: gift.nombre,
			precio: gift.precio,
			imagen: gift.imagen
		}
		let { selectedGifts } = this.state;
		if (selectedGifts.find(g => g.id_producto == gift.id_producto)) {
			selectedGifts = selectedGifts.filter(g => g.id_producto != gift.id_producto)
		} else {
			selectedGifts.push(giftInfo);
		}
		this.setState({selectedGifts});

		//Guardar en localstorage
		const { currentSale } = this.state;
		let localData = localStorage.getItem('aroos_sale') || '[]';
		if (localData) {
			localData = JSON.parse(localData);
			if (Array.isArray(localData)) {
				if (localData.find(ld => ld.id_producto == currentSale)) {
					localData = localData.map(ld => {
						if (ld.id_producto == currentSale) {
							ld.gifts = selectedGifts;
						}
						return ld;
					})
				}
			}
		}
		localStorage.setItem('aroos_sale', JSON.stringify(localData));
	}

	render() {
		if (this.state.loading) {
			return (
				<div className="column justify-center align-center">
					<h1>{'Loading...'}</h1>
				</div>
			)
		}

		return (
			<div className="column align-center justify-center">
				<h1>Select Gifts</h1>
				<div className="white-space-32"/>
				<div className="order-now">
					<Link to="/message" className="btn btn-primary-reverse">Continue Order</Link>
				</div>
				<div className="white-space-16"></div>
				<Gifts
					gifts			=	{this.state.gifts}
					loading			= 	{this.state.loading}
					selectGift		=	{this.selectGift.bind(this)}
					selectedGifts	=	{this.state.selectedGifts}/>
			</div>
		)
	}
}

export default GiftOptions;
