import React, {useState, useEffect} from 'react';
import Product from './product-card';

const Products = (props) => {
	const [state, setState] = useState({products: [], prevProps: {}})
	useEffect(() =>{
		if (props !== state.prevProps) {
			setState({...state, products: props.products, prevProps: props});
		}
	});
	return (
		<div className="container column justify-center align-center">
			<div className="products">
				{state.products.map(prod => <Product key={prod.id_producto} product = {prod}/>)}
			</div>
		</div>
	)
}

export default Products;