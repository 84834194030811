import React from 'react';

const LoginForm = (props) => {
	return (
		<div className="column justify-center align-center">
			<label className="title">USER LOGIN</label>
			<div className="white-space-8"></div>
			<label className="subtitle">Welcome back</label>
			<div className="white-space-32"></div>
			<div className="row login-inputs full justify-between align-center">
				<i className="fas fa-user"></i>
				<input type="email" className="input input-text" name="email" id="LoginForm-email" placeholder="email"></input>
			</div>
			<div className="white-space-16"></div>
			<div className="row login-inputs full justify-between align-center">
				<i className="fas fa-lock"></i>
				<input type="password" className="input input-text" name="password" id="LoginForm-password"></input>
			</div>
			<div className="white-space-8"></div>
			<div className="row full justify-end">
				<a className="forgot-link">Forgot password?</a>
			</div>
		</div>
	)
}

export default LoginForm;
