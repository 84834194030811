import React from 'react';
import Routes from './core/routes';

function App() {
	return (
		<Routes/>
	);
}

export default App;
