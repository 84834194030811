import React, {useState, useEffect} from 'react';
import GiftCard from './gift-card';

const Gifts = (props) => {
	const [state, setState] = useState({gifts: [], prevProps: {}})
	useEffect(() =>{
		if (props !== state.prevProps) {
			setState({...state, gifts: props.gifts, prevProps: props});
		}
	});
	return (
		<div className="container column justify-center align-center">
			<div className="gifts">
				{state.gifts.map(prod => {
					let selected = false;
					if (Array.isArray(props.selectedGifts)) {
						if (props.selectedGifts.find(sG => sG.id_producto == prod.id_producto)) {
							selected = true;
						}
					}
					return <GiftCard key={prod.id_producto} product = {prod} selectGift = {props.selectGift.bind(this)} selected = {selected}/>
				})}
			</div>
		</div>
	)
}

export default Gifts;