import React, { Component } from 'react';
import { Consumer } from '../context';
// import { Link } from 'react-router-dom';

class Message extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fullName: '',
			location: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			zipCode: '',
			cellphone: '',
			email: '',
			deliveryInstructions: ''
		}
	}

	componentDidMount() {
		this.loadRecipient();
	}

	async loadRecipient() {
		let id_producto = localStorage.getItem('aroos_current_sale');
		if (id_producto) {
			id_producto = parseInt(id_producto);
			let localData = localStorage.getItem('aroos_sale');
			if (localData) {
				localData = JSON.parse(localData);
				if (Array.isArray(localData)) {
					const product = localData.find(ld => ld.id_producto == id_producto);
					console.log(product);
					if (product) {
						const { recipient } = product;
						if (recipient) {
							this.setState({
								fullName: 	recipient.fullName || '',
								location: 	recipient.location || '',
								address1: 	recipient.address1 || '',
								address2: 	recipient.address2 || '',
								city: 		recipient.city || '',
								state: 		recipient.state || '',
								zipCode: 	recipient.zipCode || '',
								cellphone: 	recipient.cellphone || '',
								email: 		recipient.email || '',
								deliveryInstructions: recipient.deliveryInstructions || '',
							});
						}
					}
				}
			}
		}
	}

	async setLocalStorage(key, value) {
		let id_producto = localStorage.getItem('aroos_current_sale');
		if (id_producto) {
			id_producto = parseInt(id_producto);
			let localData = localStorage.getItem('aroos_sale');
			if (localData) {
				localData = JSON.parse(localData);
				if (Array.isArray(localData)) {
					localData.map(ld => {
						if (ld.id_producto == id_producto); {
							if (!ld.recipient) {
								ld.recipient = {};
							}
							ld['recipient'][key] = value;
						}
						return ld;
					})
					localStorage.setItem('aroos_sale', JSON.stringify(localData));
				}
			}
		}
	}

	setValue(key, event) {
		const {value} = event.target;
		const currentState = this.state;
		currentState[key] = value;
		this.setState({...currentState});
		this.setLocalStorage(key, value);
	}

	handleSubmit(event) {
		event.preventDefault();
		this.props.history.push('/order');
	}

	render() {
		return (
			<form className="recipient column align-center" onSubmit={this.handleSubmit.bind(this)}>
				<h1>Who is this for?</h1>
				<div className="column justify-center align-center">
					<div className="white-space-32"/>
					<div className="order-now full justify-center">
						<button className="btn btn-primary-reverse">
							Continue Order
						</button>
					</div>
					<div className="white-space-16"/>
					<div className="form-input row full align-center justify-center">
						<input
							className="input input-text"
							maxLength="500"
							placeholder="Their Full Name"
							value={this.state.fullName}
							onChange={this.setValue.bind(this, 'fullName')}
							required
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<select
							className="input input-select" value={this.state.location}
							onChange={this.setValue.bind(this, 'location')}
							required>
							<option value="">Select location</option>
							<option value="home">Home/Residence</option>
							<option value="business">Business</option>
							<option value="funeral">Funeral Home</option>
							<option value="hospital">Hospital</option>
							<option value="school">School</option>
						</select>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							className="input input-text"
							maxLength="250"
							placeholder="Address line 1"
							value={this.state.address1}
							onChange={this.setValue.bind(this, 'address1')}
							required
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							className="input input-text"
							maxLength="250"
							placeholder="Address line 2"
							value={this.state.address2}
							onChange={this.setValue.bind(this, 'address2')}
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							className="input input-text"
							maxLength="250"
							placeholder="City"
							value={this.state.city}
							onChange={this.setValue.bind(this, 'city')}
							required
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							className="input input-text"
							maxLength="250"
							placeholder="State"
							value={this.state.state}
							onChange={this.setValue.bind(this, 'state')}
							required
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							className="input input-text"
							maxLength="250"
							placeholder="Zip Code"
							value={this.state.zipCode}
							onChange={this.setValue.bind(this, 'zipCode')}
							required
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							className="input input-text"
							maxLength="250"
							placeholder="Cell Phone Number"
							value={this.state.cellphone}
							onChange={this.setValue.bind(this, 'cellphone')}
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							type="email"
							className="input input-text"
							maxLength="250"
							placeholder="Email"
							value={this.state.email}
							onChange={this.setValue.bind(this, 'email')}
							required
						/>
					</div>
					<div className="form-input row full align-center justify-center">
						<input
							type="email"
							className="input input-text"
							maxLength="250"
							placeholder="Delivery Instructions, ie. Call first, ring the back doorbell"
							value={this.state.deliveryInstructions}
							onChange={this.setValue.bind(this, 'deliveryInstructions')}
						/>
					</div>
					<div className="white-space-16"></div>
					<div className="order-now full justify-center">
						<button className="btn btn-primary-reverse">
							Continue Order
						</button>
					</div>
				</div>
			</form>
		)
	}
}

export default Consumer(Message);