import React, { useState } from 'react';
import SocialMedia from './social-media';
import DinamicModal from './dinamicModal/dinamicModal';
import { Consumer } from '../context';
import LoginForm from './login/form';
import { Link } from 'react-router-dom';

const Header = Consumer((props) => {
	const { context } = props;
	return (
		<nav className="header row justify-center align-center">
			<div className="flowers" />
			<div className="content column justify-start">
				<div className="row justify-between">
					<div className="left">
						<Link to="/">
							<img src="/images/logo.png" alt="logo" width="180px"/>
						</Link>
					</div>
					<div className="right">
						<div className="column align-end">
							<SocialMedia />
							<div className="white-space-16"></div>
							<div className="session row full justify-center">
								<span onClick={() => context.action('OPEN_MODAL', 'modal_login')}>Login</span>
								<span>Sign Up</span>
							</div>
						</div>
					</div>
				</div>
				<div className="menu">
				</div>
			</div>
			<Login />
		</nav>
	)
});

const Login = Consumer((props) => {
	const [loading, setLoading] = useState(false);

	function Login(event) {
		setLoading(true);
		event.preventDefault();
		const form = event.target;
		const data = {
			username: form.username.value
		}
		console.log(form);
		setLoading(false);
	}

	return (
		<DinamicModal
			idModal 		= "modal_login"
			sizeModalContainer = "small"
			loadingSave 	= {loading}
			onsuccess 		= {Login.bind(this)}
			btnTextCancel	= "Cancel"
			showBtnCancel	= {false}
			btnTextSuccess	= "LOGIN"
			showBtnSuccess	= {true}>
			<LoginForm />
		</DinamicModal>
	)
});

export default Header;