import React from 'react';
import SocialMedia from '../components/social-media';

const Footer = () => {
	return (
		<div className="footer column justify-between align-center">
			<div className="white-space-8"></div>
			<h4>Aroos Flowers</h4>
			<div className="row justify-center">
				<SocialMedia />
			</div>
			<span className="develop">
				develop by <a href="https://marianosoft.com">marianosoft.com</a>
			</span>
		</div>
	)
}

export default Footer;