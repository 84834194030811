import React, { useDebugValue, useEffect, useState } from 'react';
import { Consumer } from '../context';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Carousel from '@brainhubeu/react-carousel';
import {formatNumber} from '../core/functions';
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Request from '../core/apiService';

const req = new Request();

const intProducto = {
	activo: 1,
	descripcion:'',
	id_categoria: 0,
	id_producto: 0,
	imagenes: [],
	nombre:'',
	precio: 0,
	selectedImage: '',
};

const Order = () => {
	const [ producto, setProducto] = useState(intProducto);
	const [ productImage, setProductImage ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const [ total, setTotal ] = useState(0);
	const [ loadingSave, setLoadingSave ] = useState(false);
	const [	category, setCategory ] = useState({});
	const [	dateOption, setDateOption ] = useState('today');
	const [	delivery, setDelivery ] = useState('delivery');
	const [	dateDelivery, setDateDelivery ] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
	const [ recipient, setRecipient ] = useState(null);
	const [ message, setMessage ] = useState('');
	const [ occasion, setOccasion ] = useState('');
	const [	gifts, setGifts] = useState([]);

	useEffect(() => {
		loadProduct();
		document.body.scrollTop = 0; // For Safari
  		document.documentElement.scrollTop = 0;
	}, [])

	async function loadProduct() {
		setLoading(true);

		const id_producto = localStorage.getItem('aroos_current_sale');
		const res = await req.post('producto/detalle', { id_producto });
		const { producto }  = res;
		if (producto) {
			setProducto(producto);
			loadDetails(id_producto);
		} else {
			cogoToast.error(res.message || 'Internal error', {heading: 'OOPS!!'});
		}

		setLoading(false);
	}

	function loadDetails(id_producto) {

		let localData = localStorage.getItem('aroos_sale');

		if (localData) {

			localData = JSON.parse(localData);

			if (Array.isArray(localData)) {

				const product = localData.find(ld => ld.id_producto == id_producto);

				if (product) {
					const giftTotal = product.gifts? product.gifts.reduce((prev, curr) => (prev + parseFloat(curr.precio)), 0) : 0;
					let total = parseFloat(product.categoria.precio) + giftTotal;
					setTotal(total);
					setDateOption(product.dateOption);
					setDateDelivery(product.dateDelivery);
					setDelivery(product.delivery);
					setGifts(product.gifts || []);
					setRecipient(product.recipient);
					setMessage(product.message);
					setOccasion(product.occasion);
					setProductImage(product.imagen);
					setCategory(product.categoria);
				}
			}
		} else {
			cogoToast.error('Internal error', {heading: 'OOPS!!'});
		}
	}

	function openModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('show-dinamic-modal');
			modal.classList.remove('hide-dinamic-modal');
		}
	}

	function closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

	async function saveOrder(event) {
		event.preventDefault();

		setLoadingSave(true);

		const data = {
			total: total,
			dateOption: dateOption,
			fecha_envio: dateDelivery,
			tipo_envio: delivery,
			gifts: gifts,
			id_producto: producto.id_producto,
			cliente: recipient,
			mensaje: message,
			ocasion: occasion,
			categoria: category,
			creado: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		const res = await req.post('pedido/crear', data);
		if (res.created) {
			closeModal('modal-complete-order');
			cogoToast.success(res.message || `Your order has been created with the id #${res.id_pedido}`, { heading: 'SUCCESS!!', hideAfter: 3})
			.then(() => {
				localStorage.removeItem('aroos_current_sale');
				localStorage.removeItem('aroos_sale');
				window.location = '/';
			});;
		} else {
			cogoToast.error(res.message || 'Internal error', {heading: 'OOPS!!'});
		}

		setLoadingSave(false);
	}

	if (!producto.activo) {
		return (
			<div className="column justify-center align-center">
				<h1>{'Product Unavailable'}</h1>
			</div>
		)
	}

	if (loading) {
		return (
			<div className="column justify-center align-center">
				<h1>{'Loading...'}</h1>
			</div>
		)
	}

	const Gift = (gift) => {
		return (
			<div className="gift">
				<section>
					<img src={gift.imagen || 'https://www.dia.org/sites/default/files/No_Img_Avail.jpg'} alt={'currentImage'} />
				</section>
				<div className="column align-center justify-center">
					<section>
						{gift.nombre}
					</section>
					<div className="white-space-8"></div>
					<section>
						{formatNumber(gift.precio, 2, '.', ',', '$')}
					</section>
				</div>
			</div>
		)
	}

	return (
		<div className="complete-order column container align-center">
			<div className="product-detail">
				<div className="product-images">
					<div className="current-image">
						<img src={productImage || 'https://www.dia.org/sites/default/files/No_Img_Avail.jpg'} alt={'currentImage'} />
					</div>
				</div>
				<div className="product-description">
					<div className="title">
						<h1>{producto.nombre}</h1>
					</div>
					<div className="white-space-8"></div>
					<div className="description">
						<section>
							Category: <b>{category.nombre}</b>
						</section>
						<div className="white-space-16"></div>
						<section>
							Price: <b>{formatNumber(category.precio, 2, '.', ',', '$')}</b>
						</section>
						<div className="white-space-16"></div>
						<section>
							Order Type: <b>{delivery}</b>
						</section>
						<div className="white-space-16"></div>
						<section>
							Date delivery: <b>{moment(dateDelivery).format('ddd DD MMMM YYYY')}</b>
						</section>
						<div className="white-space-16"></div>
						<section className="gifts">
							<span>Gifts:</span>
							<Carousel
								dots
								slidesPerPage="1">
							{gifts.map(gift => <Gift
															key		=	{gift.id_producto}
															id		=	{gift.id_producto}
															nombre	=	{gift.nombre}
															imagen	=	{gift.imagen}
															precio	=	{gift.precio}/>)}
							</Carousel>
						</section>
						<div className="white-space-16"></div>
						<section className="total">
							Grand Total: <b>{formatNumber(total, 2, '.', ',', '$')}</b>
						</section>
						<div className="white-space-16"></div>
					</div>
					<div className="order-now">
						<button className="btn btn-primary" onClick={openModal.bind(this, 'modal-complete-order')}>Complete Order</button>
					</div>
				</div>
			</div>
			<DinamicModal
				idModal 		= "modal-complete-order"
				sizeModalContainer = "small"
				loadingSave 	= {loadingSave}
				success 		= {saveOrder.bind(this)}
				btnTextCancel	= "Cancel"
				showBtnCancel	= {true}
				btnTextSuccess	= "Accept"
				showBtnSuccess	= {true}>
				<div className="row justify-center align-center modal-order">
					<p>
						By clicking accept, you accept our &nbsp; <a href="/policies" target="_newtab">purchasing policies.</a>
					</p>
				</div>
			</DinamicModal>
		</div>
	)
}

export default Consumer(Order);
