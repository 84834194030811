import { openModal } from './modal';

function Functions (action, value) {
	switch(action) {
		case 'OPEN_MODAL':
			openModal(value);
			break;
		case 'LOGIN':
			this.setState({auth: true});
			break;
		default:
			break;
	}
}

export default Functions;
