import React, { Component } from 'react';

class NewModal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			sizeModal: "column dinamic-modal-container dinamic-modal-normal"
		}
	}
	
	componentDidMount() {
		switch(this.props.sizeModalContainer) {
			case "big" : 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-big"});
				break;
			case "normal" : 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-normal"});
				break;
			case "small" : 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-small"});
				break;
			case "medium":
			this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-medium"});
			break;
			case "super":
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-super-big"});
				break;
			default: 
				this.setState({sizeModal: "column dinamic-modal-container dinamic-modal-normal"});
				break;
		}
	}

    render() {
        return (
			<div className= "overlay hide-dinamic-modal"  id={this.props.idModal}>
				<div className= {this.state.sizeModal} >
					<div className="dinamic-header row justify-center">
						<div className="logo">
							<img src="/images/logo.png" alt="Aroos Flowers"></img>
						</div>
						{ this.props.idModal == "modal_login" && <button className="btn-create">Create Account</button>}
						<div className="row justify-center modal-header-flowers">
							<img src="/images/flower2.png" alt="flowers"></img>
						</div>
						<div className="column modal-container justify-center">
							<h3 className="title">{this.props.title}</h3>
						</div>
						<button className="btn-modal-close justify-center align-center" onClick={this.closeModal.bind(this,this.props.idModal)} >
							<i className="fas fa-times"></i>
						</button>
					</div>
					<div className="white-space-32"></div>
					<div className="dinamic-body row justify-center">
						<div className={this.props.idModal == "modal_login" ? "column modal-container-login" :"column modal-container"}>
							<form className="flex column justify-beween" onSubmit={this.props.success}>
								{this.props.children}
								<div className="white-space-32"></div>
								<div className="dinamic-footer row justify-center">
									{this.props.loadingSave ?
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div> :
										<div className="row justify-center">
										{this.props.showBtnSuccess &&
											<button type="submit" className={this.props.idModal == "modal_login" ? "btn-action-login" : "btn-action-success"} disabled={this.props.disabledBtnSuccess}>
												{this.props.idModal == "modal_login" ? null : <i className="fas fa-check" />  }
												{this.props.btnTextSuccess}
											</button>
										}
										{this.props.showBtnCancel &&
											<button type="button" className="btn-action-cancel" onClick={this.closeModal.bind(this,this.props.idModal)}>
												<i className="fas fa-times" />  {this.props.btnTextCancel}
											</button>
										}
										</div>
									}
								</div>
							</form>
						</div>
					</div>
					<div className="white-space-24"></div>
				</div>
			</div>
        );
	}
	
	openModal() {
		let modal = document.getElementById(this.state.idModals);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}
   
	closeModal(idModal) {
		if(this.props.closeModal) {
			this.props.closeModal();
		}
		const modal = document.getElementById(idModal) 
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}
}



export default NewModal;