import React, { Component } from 'react';
import { Consumer } from '../context';
import { Link } from 'react-router-dom';

class Message extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: '',
			occasion: ''
		}
	}

	componentDidMount() {
		this.loadMessage();
	}

	async loadMessage() {
		let id_producto = localStorage.getItem('aroos_current_sale');
		if (id_producto) {
			id_producto = parseInt(id_producto);
			let localData = localStorage.getItem('aroos_sale');
			if (localData) {
				localData = JSON.parse(localData);
				if (Array.isArray(localData)) {
					const product = localData.find(ld => ld.id_producto == id_producto);
					console.log(product);
					if (product) {
						this.setState({occasion: product.occasion || '', message: product.message || ''});
					}
				}
			}
		}
	}

	async setLocalStorage(key, value) {
		let id_producto = localStorage.getItem('aroos_current_sale');
		if (id_producto) {
			id_producto = parseInt(id_producto);
			let localData = localStorage.getItem('aroos_sale');
			if (localData) {
				localData = JSON.parse(localData);
				if (Array.isArray(localData)) {
					localData.map(ld => {
						if (ld.id_producto == id_producto); {
							ld[key] = value;
						}
						return ld;
					})
					localStorage.setItem('aroos_sale', JSON.stringify(localData));
				}
			}
		}
	}

	selectOccasion(event) {
		const {value} = event.target;
		this.setState({occasion: value});
		this.setLocalStorage('occasion', value);
	}

	setMessage(event) {
		const {value} = event.target;
		this.setState({message: value});
		this.setLocalStorage('message', value);
	}

	handleSubmit(event) {
		event.preventDefault();
		this.props.history.push('/recipient');
	}

	render() {
		return (
			<div className="message column align-center">
				<form onSubmit={this.handleSubmit.bind(this)}>
					<h1>Card Messge</h1>
					<div className="white-space-32"/>
					<div className="order-now">
						<button type="submit" className="btn btn-primary-reverse">Continue Order</button>
					</div>
					<div className="white-space-16"/>
					<div className="column justify-center align-center">
						<div className="occasion row full align-center justify-center">
							<select
								className="input input-select"
								value={this.state.occasion}
								onChange={this.selectOccasion.bind(this)}
								required>
								<option value="">Select Occasion</option>
								<option value="birthday">Birthday</option>
								<option value="graduation">Graduation</option>
								<option value="anniversary">Anniversary</option>
							</select>
						</div>
						<div className="message-text row full align-center justify-center">
							<textarea
								className="input input-text-area"
								rows="8"
								maxLength="288"
								placeholder="Write your message here..."
								value={this.state.message}
								onChange={this.setMessage.bind(this)}
								required
							/>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default Consumer(Message);