import React, { useState, useEffect } from 'react';

const Gift = (props) => {
	const [prevProps, setPrevProps] = useState({});
	const [state, setState] = useState({selected: false});
	const { product } = props;

	useEffect(() => {
		if (props !== prevProps) {
			setState({
				...state,
				selected: props.selected
			});
			setPrevProps(props);
		}
	})

	return (
		<div className={`gift-card ${state.selected? 'selected' : ''}`} onClick={() => props.selectGift(product)}>
			<img src={product.imagen} alt="gift"></img>
			<div className="card-info row justify-between">
			<div className="card-name">{product.nombre}</div>
				<div className="card-price">{product.precio}</div>
			</div>
		</div>
	)
}

export default Gift;