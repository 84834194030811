import React, { Component, createContext } from 'react';
import Functions from './functions';

const Context = createContext();

class Provider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			auth: false,
			action: Functions.bind(this)
		}
	}

	render() {
		return (
			<Context.Provider value={this.state}>
				{this.props.children}
			</Context.Provider>
		)
	}
}

const Consumer = Component => {
    return props => {
        return (
            <Context.Consumer>
                { context => <Component { ...props } context = { context } /> }
            </Context.Consumer>
        );
    };
};

export { Provider, Consumer }