import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import Home from '../pages/home';
import Detail from '../pages/detail';
import GiftOptions from '../pages/gift_options';
import Message from '../pages/message';
import Recipient from '../pages/recipient';
import Order from '../pages/order';
import { Provider } from '../context';
//Styles
import './styles/styles.scss';
import 'react-calendar/dist/Calendar.css';
import '@brainhubeu/react-carousel/lib/style.css';

const routes = () => {
	return (
		<Provider>
			<BrowserRouter>
				<div className="conatiner column align-center justify-center">
					<Header />
					<Switch>
						<Route path="/" exact component={Home} />
						<Route path="/detail/:id" exact component={Detail} />
						<Route path="/gift_options" exact component={GiftOptions} />
						<Route path="/message" exact component={Message} />
						<Route path="/recipient" exact component={Recipient} />
						<Route path="/order" exact component={Order} />
					</Switch>
					<div className="white-space-32"></div>
					<Footer />
				</div>
			</BrowserRouter>
		</Provider>
	)
};

export default routes;