import React from 'react';
import { Link } from 'react-router-dom';

const Product = (props) => {
	const { product } = props;
	return (
		<Link className="product-card" to={`/detail/${product.id_producto}`}>
			<img src={product.imagen} alt="product"></img>
			<div className="card-info row justify-between">
			<div className="card-name">{product.nombre}</div>
				<div className="card-price">{product.precio}</div>
			</div>
		</Link>
	)
}

export default Product;