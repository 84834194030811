import React from 'react';
import Facebook from "./icons/facebook.png";
import Instagram from "./icons/instagram.png";
import Whatsapp from "./icons/whatsapp.png";
import "./style.css";

const SocialMedia = () => {
	return (
		<span className="styleSpan">
			<a href="https://facebook.com" target="_newtab" className="styleLink">
				<img src={Facebook} alt="facebook" className="styleIcon"></img>
			</a>
			<a href="https://instagram.com" target="_newtab" className="styleLink">
				<img src={Instagram} alt="instagram" className="styleIcon"></img>
			</a>
			<a href="https://whatsapp.com" target="_newtab" className="styleLink">
				<img src={Whatsapp} alt="whatsapp" className="styleIcon"></img>
			</a>
		</span>
	)
}

export default SocialMedia;