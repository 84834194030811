import React, { useEffect, useState } from 'react';
import { Consumer } from '../context';
import { Link, useParams, useHistory } from 'react-router-dom';
import Request from '../core/apiService';
import { formatNumber } from '../core/functions';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';
import "react-datepicker/dist/react-datepicker.css";
import DinamicModal from '../components/dinamicModal/dinamicModal';
import Carousel from '@brainhubeu/react-carousel';

const req = new Request();

const Detail = () => {
	const [loading, setLoading] = useState(true);
	const [producto, setProducto] = useState (
		{
			activo: 1,
			descripcion:'',
			id_categoria: 0,
			id_producto: 0,
			imagenes: [],
			nombre:'',
			precio: 0,
			selectedImage: '',
			categorias: []
		}
	);
	const [selectedCategoria, setSelectedCategoria] = useState(0);
	const [selectedImage, setSelectedImage] = useState('');
	const [selectedDate, setSelectedDate] = useState(null);
	const [precio, setPrecio] = useState(null);
	const [dateOption, setDateOption] = useState('today');
	const [delivery, setDelivery] = useState('delivery');
	const [dateDelivery, setDateDelivery] = useState(moment().add(1, 'day').format('YYYY-MM-DD'));
	const [minDate, setMinDate] = useState(new Date());

	const params = useParams();
	const history = useHistory();

	useEffect(() => {
		loadProduct();

		document.body.scrollTop = 0; // For Safari
  		document.documentElement.scrollTop = 0;
	}, []);

	async function loadProduct() {
		//minDate
		let newMinDate = new Date();
		newMinDate.setDate(minDate.getDate() + 4);
		setMinDate(newMinDate);

		setLoading(true);

		const data = {
			id_producto: params.id,
			dateOption: dateOption,
			dateDelivery: dateDelivery,
			delivery: delivery,
		};

		//Guardar en localstorage
		let localData = localStorage.getItem('aroos_sale') || '[]';

		if (localData) {

			localData = JSON.parse(localData);

			if (Array.isArray(localData)) {

				const product = localData.find(ld => ld.id_producto == data.id_producto);

				if (product) {

					setDateOption(product.dateOption);
					setDateDelivery(product.dateDelivery);
					setDelivery(product.delivery);

				} else {
					localData.push(data);
				}
			}
		}

		localStorage.setItem('aroos_sale', JSON.stringify(localData));
		localStorage.setItem('aroos_current_sale', data.id_producto);

		const res = await req.post('producto/detalle', data);

		const { producto }  = res;

		if (producto) {
			setProducto(producto);
			const categoria = producto.categorias[0];
			if (categoria) {
				setSelectedImage(categoria.imagen);
			}
		} else {
			cogoToast.error(res.message || 'Internal error', {heading: 'OOPS!!'});
		}

		setLoading(false);
	}

	function selecteDate(date) {
		setSelectedDate(date);
		selectOptionDate('moredates', moment(date).format('YYYY-MM-DD'));
		closeModal('calendar-modal');
	}

	function openModal(idModal) {
		setDateOption('moredates');
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.add('show-dinamic-modal');
			modal.classList.remove('hide-dinamic-modal');
		}
	}

	function closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

	function selectOptionDate(dateOption, dateDelivery) {
		setDateOption(dateOption);
		setDateDelivery(dateDelivery);

		//Guardar en localstorage
		let localData = localStorage.getItem('aroos_sale') || '[]';

		if (localData) {
			localData = JSON.parse(localData);
			if (Array.isArray(localData)) {
				if (localData.find(ld => ld.id_producto == producto.id_producto)) {
					localData = localData.map(ld => {
						if (ld.id_producto == producto.id_producto) {
							ld.dateOption = dateOption;
							ld.dateDelivery = dateDelivery;
						}
						return ld;
					})
				}
			}
		}

		localStorage.setItem('aroos_sale', JSON.stringify(localData));
	}

	function selectDelivery(delivery) {
		setDelivery(delivery);
	}

	if (!producto.activo) {
		return (
			<div className="column justify-center align-center">
				<h1>{'Product Unavailable'}</h1>
			</div>
		)
	}

	if (loading) {
		return (
			<div className="column justify-center align-center">
				<h1>{'Loading...'}</h1>
			</div>
		)
	}

	//Dates
	const today = moment().add(1, 'day');
	const tomorrow = moment().add(2, 'day');
	const aftertomorrow = moment().add(3, 'day');

	function ordeNow() {
		//Guardar en localstorage
		let localData = localStorage.getItem('aroos_sale') || '[]';
		if (localData) {
			localData = JSON.parse(localData);
			if (Array.isArray(localData)) {
				if (localData.find(ld => ld.id_producto == producto.id_producto)) {
					localData = localData.map(ld => {
						if (ld.id_producto == producto.id_producto) {
							ld.delivery = delivery;
							ld.categoria = {id: selectedCategoria.id, nombre: selectedCategoria.nombre, precio: selectedCategoria.precio};
							ld.imagen = selectedCategoria.imagen
						}
						return ld;
					})
				}
			}
		}

		localStorage.setItem('aroos_sale', JSON.stringify(localData));

		if (selectedCategoria.id > 0 && precio) {
			history.push('/gift_options');
		} else {
			cogoToast.warn('Select a valid category', { heading: 'Hey!!', position: 'top-center'});
		}
	}

	return (
		<div className="column container align-center">
			<div className="product-detail">
				<div className="product-images">
					<div className="current-image">
						<img src={selectedImage} alt={'currentImage'} />
					</div>
				</div>
				<div className="product-description">
					<div className="title">
						<h1>{producto.nombre}</h1>
					</div>
					<div className="white-space-16"></div>
					<div className="description">
						<section className="categorias row justify-between">
							{producto.categorias.map(cat => {
								return (
									<div
										key={cat.id}
										className={`categoria column full ${selectedCategoria.id == cat.id && 'selected'}`}
										onClick={() => {
											setSelectedCategoria(cat);
											setSelectedImage(cat.imagen);
											setPrecio(cat.precio);
										}}>
										<h1>{cat.nombre}</h1>
										<h4>{formatNumber(cat.precio, 2, '.', ',', '$')}</h4>
									</div>
								)
							})}
						</section>
						<section>
							{producto.descripcion}
						</section>
					</div>
					<div className="white-space-16"></div>
					<div className="delivery">
						<h3>When do you need it?</h3>
						<div className="white-space-16"></div>
						<div className="where row full">
							<span
								className={`${delivery === 'delivery' ? 'selected' : ''}`}
								onClick={selectDelivery.bind(this, 'delivery')}>
								Delivery
							</span>
							<span
								className={`${delivery === 'pickup' ? 'selected' : ''}`}
								onClick={selectDelivery.bind(this, 'pickup')}>
								Pick Up
							</span>
						</div>
					</div>
					<div className="white-space-8"></div>
					<div className="dates">
						<span
							className={`date ${dateOption === 'today' ? 'selected' : ''}`}
							onClick={() => selectOptionDate('today', today.format('YYYY-MM-DD'))}>
							<section>
								{today.format('ddd')}
							</section>
							<section>
								{today.format('MMMM - DD')}
							</section>
						</span>
						<span
							className={`date ${dateOption === 'tomorrow' ? 'selected' : ''}`}
							onClick={() => selectOptionDate('tomorrow', tomorrow.format('YYYY-MM-DD'))}>
							<section>
								{tomorrow.format('ddd')}
							</section>
							<section>
								{tomorrow.format('MMMM - DD')}
							</section>
						</span>
						<span
							className={`date ${dateOption === 'aftertomorrow' ? 'selected' : ''}`}
							onClick={() => selectOptionDate('aftertomorrow', aftertomorrow.format('YYYY-MM-DD'))}>
							<section>
								{aftertomorrow.format('ddd')}
							</section>
							<section>
								{aftertomorrow.format('MMMM - DD')}
							</section>
						</span>
						<span
							className={`date ${dateOption === 'moredates' ? 'selected' : ''}`}
							onClick={openModal.bind(this, 'calendar-modal')}>
							{!selectedDate?
								<section>
									More dates
								</section>
								:
								<>
									<section>
										{moment(selectedDate).format('ddd')}
									</section>
									<section>
										{moment(selectedDate).format('MMMM - DD')}
									</section>
								</>
							}
						</span>
					</div>
					<div className="white-space-8"></div>
					<div className="order-now">
						<Link onClick={ordeNow.bind(this)} className="btn btn-primary">Order Now</Link>
					</div>
				</div>
			</div>
			<DinamicModal
				idModal				=	{'calendar-modal'}
				sizeModalContainer  =	{'normal'}
				>
					<div className="row justify-center">
						<Calendar
							onChange	=	{selecteDate.bind(this)}
							value		=	{selectedDate}
							minDate		=	{minDate}
						/>
					</div>
			</DinamicModal>
		</div>
	)
}


export default Consumer(Detail);
