export const formatNumber = (number, decPlaces, decSep, thouSep, symbol) => {
		let DecPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
		let DecSep = typeof decSep === "undefined" ? "." : decSep;
		let ThouSep = typeof thouSep === "undefined" ? "," : thouSep;
		var sign = number < 0 ? "-" : "";
		var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
		var j = (j = i.length) > 3 ? j % 3 : 0;
		
		return symbol + sign + (j ? i.substr(0, j) + ThouSep : "") +
			i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + ThouSep) +
			(DecPlaces ? DecSep + Math.abs(number - i).toFixed(DecPlaces).slice(2) : "");
}